<template>
  <div id="app" style="font-family: 'Shippori Mincho', serif;">
    <!-- ヘッダー -->
    <header class="flex items-center px-10 bg-yellow-300 h-20">
      <a href="/">
        <img class="h-10 bg-white rounded-full" src="./assets/logo.png">
      </a>
      <h1 class="px-10 w-full items-center text-4xl font-bold text-gray-700">itr page</h1>
    </header>
    <BugerMenu/>
    <router-view  style="min-height: calc(100vh - 10rem)" />

    <!-- フッター -->
    <footer class="bg-gray-700">
      <p class="flex items-center justify-center text-white h-20">Copyright 2023 Risa Ito</p>
    </footer>
  </div>
</template>

<script>
import BugerMenu from '@/components/BugerMenu.vue'
export default{
  components:{
    BugerMenu  
  }
}

</script>
<style>
 @import url('https://fonts.googleapis.com/css2?family=Shippori+Mincho&display=swap');
</style>