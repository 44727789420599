<template>
   <div class="menuBox">
     <Slide
       right
       class="slideMenu"
       width="200"
     >
       <router-link to="/" tag="li">
         Top
       </router-link>
       <router-link to="/about" tag="li">
         About
       </router-link>
       <router-link to="/research" tag="li">
         Research
       </router-link>
       <router-link to="/contact" tag="li">
         Contact
       </router-link>
     </Slide>
   </div>
 </template>

 <script>
 import { Slide } from 'vue-burger-menu'

 export default {
   components: {
     Slide
   }
 }
 </script>

 <style lang="postcss" scoped>
   li:hover{
     background-color:#ffcc33;
     color:white;
   }
 </style>
